import Vue from 'vue'
import VueRouter from 'vue-router'
import { setRem } from '../utils/rem'

// 把这段代码直接粘贴到router/index.js中的Vue.use(VueRouter)之前
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => {})
}

Vue.use(VueRouter)

const routesP = [
  { path: '/', component: () => import('../view/pc/compax/index.vue') },
  { path: '/ACE1Pro', component: () => import('../view/pc/ACE1Pro/index.vue') },
  { path: '/MK215', component: () => import('../view/pc/MK215/index.vue') },
  { path: '/ACE1', component: () => import('../view/pc/ACE1/index.vue') },
  { path: '/ET7', component: () => import('../view/pc/ET7/index.vue') },
  {
    path: '/contactUs',
    component: () => import('../view/pc/contactUs/index.vue')
  },
  { path: '/ACE2', component: () => import('../view/pc/ACE2/index.vue') },
  { path: '/D1', component: () => import('../view/pc/D1/index.vue') },
  { path: '/Y1', component: () => import('../view/pc/Y1/index.vue') },
  {
    path: '/desktopTerminals',
    component: () => import('../view/pc/desktopTerminals/index.vue')
  },
  { path: '/test', component: () => import('../view/pc/test/index.vue') },
  {
    path: '/customerCases',
    component: () => import('../view/pc/customerCases/index.vue')
  },
  {
    path: '/platformServices',
    component: () => import('../view/pc/platformServices/index.vue')
  },
  {
    path: '/siteMap',
    component: () => import('../view/pc/siteMap/index.vue')
  },
  {
    path: '/9533Series',
    component: () => import('../view/pc/9533Series/index.vue')
  },
  {
    path: '/9520Series',
    component: () => import('../view/pc/9520Series/index.vue')
  },
  {
    path: '/A90',
    component: () => import('../view/pc/A90/index.vue')
  },
  {
    path: '/Sprint800',
    component: () => import('../view/pc/Sprint800/index.vue')
  }
]

const routesM = [
  {
    path: '/',
    component: () => import('../view/phone/compax/index.vue')
  },
  { path: '/ACE2', component: () => import('../view/phone/ACE2/index.vue') },
  { path: '/ACE1', component: () => import('../view/phone/ACE1/index.vue') },
  { path: '/D1', component: () => import('../view/phone/D1/index.vue') },
  { path: '/Y1', component: () => import('../view/phone/Y1/index.vue') },
  { path: '/ET7', component: () => import('../view/phone/ET7/index.vue') },
  { path: '/MK215', component: () => import('../view/phone/MK215/index.vue') },
  {
    path: '/ACE1Pro',
    component: () => import('../view/phone/ACE1Pro/index.vue')
  },
  {
    path: '/desktopTerminals',
    component: () => import('../view/phone/desktopTerminals/index.vue')
  },
  {
    path: '/platformServices',
    component: () => import('../view/phone/platformServices/index.vue')
  },
  {
    path: '/contactUs',
    component: () => import('../view/phone/contactUs/index.vue')
  },
  {
    path: '/customerCases',
    component: () => import('../view/phone/customerCases/index.vue')
  },
  {
    path: '/siteMap',
    component: () => import('../view/pc/siteMap/index.vue')
  },
  {
    path: '/9533Series',
    component: () => import('../view/phone/9533Series/index.vue')
  },
  {
    path: '/9520Series',
    component: () => import('../view/phone/9520Series/index.vue')
  },
  {
    path: '/A90',
    component: () => import('../view/phone/A90/index.vue')
  },
  {
    path: '/Sprint800',
    component: () => import('../view/phone/Sprint800/index.vue')
  }
]

var routes = routesM
if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  routes = routesM
  setRem('phone')
  localStorage.setItem('setRem', 'phone')
} else {
  routes = routesP
  setRem('pc')
  localStorage.setItem('setRem', 'pc')
}

const router = new VueRouter({
  // mode: 'history',
  routes // (缩写) 相当于 routes: routes
})

router.beforeEach((to, from, next) => {
  next()
  window.scrollTo(0, 0)
})

// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`

//     if (route.path !== '*') {
//       array.push(path)
//     }

//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`))
//     }

//     return array
//   }, [])
// }

// getRoutesList(router.options.routes, 'https://zigamiklic.com');
// function getRoutesXML() {
//   const list = getRoutesList(router.options.routes, 'https://compax.cc/#')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n')
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`
// }

// console.log(getRoutesXML())

export default router
