<template>
  <div>
    <div class="footerContainer" v-if="type == 'pc'">
      <div class="footer w">
        <div class="content">
          <div class="left">
            <div class="item">
              <div class="title">Products</div>
              <div
                class="text cursor"
                @click="goRouter('/desktopTerminals', 'desktopTerminals')"
              >
                Desktop Terminals
              </div>
              <div
                class="text cursor"
                @click="goRouter('/desktopTerminals', 'smartWeighing')"
              >
                Smart Weighing
              </div>
              <div
                class="text cursor"
                @click="goRouter('/desktopTerminals', 'Kiosks')"
              >
                Kiosks
              </div>
              <div
                class="text cursor"
                @click="goRouter('/desktopTerminals', 'Peripherals')"
              >
                Peripherals
              </div>
              <div
                class="text cursor"
                @click="goRouter('/desktopTerminals', 'Printer')"
              >
                Printer
              </div>
            </div>
            <div class="item">
              <div class="title">Platform Services</div>
              <div class="text cursor" @click="goRouter('/PlatformServices')">
                DMS
              </div>
            </div>
            <div class="item">
              <div class="title">Customer Cases</div>
              <div
                class="text cursor"
                @click="goRouter('/customerCases', 'ShoppingCenter')"
              >
                Shopping Center
              </div>
              <div
                class="text cursor"
                @click="goRouter('/customerCases', 'FB')"
              >
                F&B
              </div>
              <div
                class="text cursor"
                @click="goRouter('/customerCases', 'Retails')"
              >
                Retails
              </div>
              <div
                class="text cursor"
                @click="goRouter('/customerCases', 'Supermarket')"
              >
                Supermarket
              </div>
            </div>
            <div class="item">
              <div class="title">Contact Us</div>
              <div
                class="text cursor"
                @click="goRouter('/contactUs', 'contactUs')"
              >
                About Us
              </div>
              <div
                class="text cursor"
                @click="goRouter('/contactUs', 'Inquiry')"
              >
                Inquiry
              </div>
            </div>
          </div>
          <!-- <div class="right">0756-1234567</div> -->
        </div>
        <div class="bottom">
          <!-- <div class="gonganInfo">
          <div>
            © 2024 珠海傲视创新科技有限公司 OSAI Inc. All Rights Reserved.
          </div>
          <div>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备2021150212号-1</a
            >
          </div>
          <div class="gongan">
            <img src="../../assets/img/goods/gongan.png" alt="" />
            <div>
              <a href="https://www.beian.gov.cn/portal/index.do" target="_blank"
                >粤公网安备44049102496925号</a
              >
            </div>
          </div>
        </div> -->
          <div class="iconBox">
            <div class="icon cursor">
              <a href="https://www.youtube.com/@compaxtech" target="_blank">
                <img src="../../assets/ico/油管.png" alt="" />
              </a>
              <!-- <svg-icon icon-class="油管"></svg-icon> -->
            </div>
            <div class="icon">
              <a
                href="https://www.facebook.com/61557660686587/"
                target="_blank"
              >
                <img src="../../assets/ico/FB.png" alt="" />
              </a>
            </div>
            <!-- <div class="icon">
            <img src="../../assets/ico/ins.png" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/ico/路径 1.png" alt="" />
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else class="footerPhone">
      <div class="content">
        <div class="item">
          <div class="title">Products</div>
          <div
            class="text cursor"
            @click="goRouter('/desktopTerminals', 'desktopTerminals')"
          >
            Desktop Terminals
          </div>
          <div
            class="text cursor"
            @click="goRouter('/desktopTerminals', 'smartWeighing')"
          >
            Smart Weighing
          </div>
          <div
            class="text cursor"
            @click="goRouter('/desktopTerminals', 'Kiosks')"
          >
            Kiosks
          </div>
          <div
            class="text cursor"
            @click="goRouter('/desktopTerminals', 'Peripherals')"
          >
            Peripherals
          </div>
          <div
            class="text cursor"
            @click="goRouter('/desktopTerminals', 'Printer')"
          >
            Printer
          </div>
        </div>
        <div class="item">
          <div class="title">Customer Cases</div>
          <div
            class="text cursor"
            @click="goRouter('/customerCases', 'ShoppingCenter')"
          >
            Shopping Center
          </div>
          <div class="text cursor" @click="goRouter('/customerCases', 'FB')">
            F&B
          </div>
          <div
            class="text cursor"
            @click="goRouter('/customerCases', 'Retails')"
          >
            Retails
          </div>
          <div
            class="text cursor"
            @click="goRouter('/customerCases', 'Supermarket')"
          >
            Supermarket
          </div>
        </div>
        <div class="item">
          <div class="title">Platform Services</div>
          <div class="text cursor" @click="goRouter('/PlatformServices')">
            DMS
          </div>
        </div>
        <div class="item">
          <div class="title">Contact Us</div>
          <div class="text cursor" @click="goRouter('/contactUs', 'contactUs')">
            About Us
          </div>
          <div class="text cursor" @click="goRouter('/contactUs', 'Inquiry')">
            Inquiry
          </div>
        </div>
      </div>
      <div class="bottom">
        <!-- <div class="gonganInfo">
          <div>
            © 2024 珠海傲视创新科技有限公司 OSAI Inc. All Rights Reserved.
          </div>
          <div>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备2021150212号-1</a
            >
          </div>
          <div class="gongan">
            <img src="../../assets/img/goods/gongan.png" alt="" />
            <div>
              <a href="https://www.beian.gov.cn/portal/index.do" target="_blank"
                >粤公网安备44049102496925号</a
              >
            </div>
          </div>
        </div> -->
        <div class="iconBox">
          <div class="icon cursor">
            <a href="https://www.youtube.com/@compaxtech" target="_blank">
              <img src="../../assets/ico/油管.png" alt="" />
            </a>
            <!-- <svg-icon icon-class="油管"></svg-icon> -->
          </div>
          <div class="icon">
            <a href="https://www.facebook.com/61557660686587/" target="_blank">
              <img src="../../assets/ico/FB.png" alt="" />
            </a>
          </div>
          <!-- <div class="icon">
            <img src="../../assets/ico/ins.png" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/ico/路径 1.png" alt="" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus.js'
export default {
  data() {
    return {
      type: 'pc'
    }
  },
  created() {
    this.type = localStorage.getItem('setRem')
  },
  methods: {
    async goRouter(val, anchor) {
      if (anchor) {
        await this.$router.push({ path: val })
        EventBus.$emit(val, anchor)
      } else {
        this.$router.push({ path: val })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footerContainer {
  padding: 100px 13px 0px;
  background: rgba(0, 0, 0, 1);
  .cursor {
    cursor: pointer;
  }
  .footer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      margin-bottom: 36px;
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;
      line-height: 23.17px;
    }
    .text {
      margin-bottom: 23px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      font-weight: 500;
      line-height: 17.38px;
    }
    .content {
      padding: 0px 11px;
      width: 100%;
      display: flex;
      justify-content: start;
      .left {
        width: 800px;
        display: flex;
        justify-content: space-between;
      }
      .right {
        color: rgba(255, 255, 255, 1);
        line-height: 52.13px;
        font-size: 36px;
        font-weight: 700;
      }
    }
    .bottom {
      margin-top: 36px;
      padding-top: 21px;
      width: 100%;
      height: 104px;
      border-top: 2px solid rgba(0, 92, 255, 0.5);
      display: flex;
      justify-content: end;
      .iconBox {
        display: flex;
        .icon {
          display: flex;
          margin-left: 24px;
          a {
            display: flex;
          }
          img {
            width: 26.47px;
            height: 26.47px;
          }
        }
      }
      .gonganInfo {
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        font-size: 12px;
        div {
          margin-right: 20px;
        }
        .gongan {
          height: fit-content;
          display: flex;
          align-items: center;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
.footerPhone {
  padding: 34px 0px 0px;
  background: rgba(0, 0, 0, 1);
  width: 100%;
  height: 649px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .cursor {
    cursor: pointer;
  }
  .content {
    margin: 0px auto;
    width: 292px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .title {
      margin-top: 30px;
      margin-bottom: 17px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      font-weight: 700;
      line-height: 23.17px;
    }
    .text {
      margin-bottom: 23px;
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      line-height: 17.38px;
    }
    .item {
      margin-right: 0px;
      width: 136px;
    }
  }
  .bottom {
    padding-top: 21px;
    width: 100%;
    height: 116px;
    border-top: 2px solid rgba(0, 92, 255, 0.5);
    display: flex;
    justify-content: end;
    .iconBox {
      display: flex;
      .icon {
        display: flex;
        margin-right: 24px;
        a {
          display: flex;
        }
        img {
          width: 26.47px;
          height: 26.47px;
        }
      }
    }
    .gonganInfo {
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      font-size: 12px;
      div {
        margin-right: 20px;
      }
      .gongan {
        height: fit-content;
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
</style>
